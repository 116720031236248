/* eslint-disable */
import $ from 'jquery';
import Hammer from 'hammerjs';
import mobile from 'is-mobile';
import browserLang from 'browser-lang';
import { twitterShare } from 'simple-social-share';
import analytics from 'universal-ga';
import Counter from './counter';
import Game from './game';

// Redirect if we are going to the share link
const url = new URL(window.location.href);
const r = url.searchParams.get("r");
if(r === 't') { window.location.href = './index.html'; };

// Redirect based on the language
const language = browserLang();
const pathArray = window.location.pathname.split('/');
const lastPath = pathArray[pathArray.length - 1];

if (language === 'ja' || language === 'es') {
  if (window.location.pathname.indexOf(language) === -1) {
      if (lastPath === '' || lastPath === '/') {
        window.location.href = `/${language}/index.html`; 
      } else {
        window.location.href = `/${language}/${lastPath}`;
      }
  }
};

$(document).ready(function() {
  analytics.initialize('UA-1049954-17');
  analytics.pageview(window.location.pathname);

  // Show the desktop information if it's not mobile
  if (!mobile()) {
    $('body').load('desktop.html #desktop',function(data){
      window.setTimeout(function() {$('main').addClass('visible');}, 1000);
      return false;
    });
  } else {
    $('main').addClass('visible');
  }

  $('.back').on('click', function(e) {
    e.preventDefault();
    window.history.back();
  });

  // Social Network
  // Facebook
  $('.end--social a.facebook').on('click', function(e) {
    e.preventDefault();
    const urlBuild = `https://facebook.com/sharer/sharer.php?u=${window.location.href}?r=t`;
    window.location.href = urlBuild;
  });

  // Twitter
  $('.end--social a.twitter').on('click', function(e) {
    e.preventDefault();
    twitterShare({ url: window.location.href, text: 'A Mighty Eater of Ramen. You deserve a cup of UltraSuperNoodles.' });
  });
  
  //Run Hammer
  const stage = document.getElementById('swipearea');
  if (stage) {

    // Get all the game init
    const hammertime = new Hammer(stage);
    const counter = new Counter();
    const game = new Game();

    let startPoint = 0;
    // Detect the swipe
    hammertime.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
    hammertime.on('swipeup', function(ev) {
      // Get the duration time for the animation
      const timeGroin = Math.abs( 2 / (ev.velocity * 10) );
      
      startPoint += Math.round(ev.distance);
      $('.scroll--noodle').css('transform', 'translateY(-'+ startPoint +'px)');
      
      game.updateNoodles();
      game.speedDistanceAverage(ev.distance, ev.timeStamp);
      game.isWinner(startPoint, countingCheck);
      
    });

    setInterval(() => {
      const heightNoodles = $('.scroll--noodle').innerHeight();
      game.updateProgress(startPoint, heightNoodles);
    }, 300);
    
    // Start the countdown of the game.
    // Remove it at the end
    function startCountDown() {
      $('.countdown').addClass('start');
      setTimeout(function(){
        $('.countdown').removeClass('start').addClass('hide');
        countingAndCheck();
        $('#swipearea').addClass('visible');
      }, 5000);
    }
    startCountDown();

    let countingCheck;
    let countingInterval;
    function countingAndCheck() {
      countingCheck = counter.updateCountdown();
      clearInterval(countingInterval);
      if (countingCheck === 0) {
        // Losing
        game.isWinner(startPoint, countingCheck, true);
      } else {
        countingInterval = setInterval(function(){
          countingAndCheck();
        }, 1000);
      }
    } 
  }

  function getTimeGame() {
    const url = new URL(window.location.href);
    const timeGame = url.searchParams.get('time');
    return timeGame;
  }

  // Redirect user to the right page
  $('.getprize').on('click', function(e) {
    e.preventDefault();
    const time = getTimeGame();
    if (time < 19) {
      window.location.href = `./perfect.html?time=${time}`;
    } else if (time < 24) {
      window.location.href = `./great.html?time=${time}`;
    } else {
      window.location.href = `./notbad.html?time=${time}`;
    }
  });

  // Generate the QR Code 
  if ($('.timing').length) {
    const time = getTimeGame();
    $('.timing').html(time);
  };

  $('.landing').height( window.innerHeight );
});