/* eslint-disable */
import $ from 'jquery';

export default class Counter {
  constructor() {
    this.time = 30;
    this.interval;
  }
  updateCountdown() {
    this.time--;
    $('.counter').html(this.time);
    return this.time;
  }
}