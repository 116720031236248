import $ from 'jquery';
import ImageManip from './imageManip';

export default class Game {
  constructor() {
    this.startPoint = 0;
    this.progressBarWidth = $('.progress--noodles').innerWidth();
    this.imageHeight = $('.scroll--noodle img').height();
    this.pourcentageProgress = 0;
    this.widthProgress = 0;
    this.timeGroin = 0;
    this.timeoutAnim = 0;
    this.addLast = 0;
    this.valueAfterSwipe = 0;
    this.noodlesToEat = 25000;
    this.difference = 0;
    this.indexPoint = 0;
    this.imagesArray = ['/static/noodles/noodle-variantA.png', '/static/noodles/noodle-variantB.png', '/static/noodles/noodle-variantC.png', '/static/noodles/noodle-variantD.png', '/static/noodles/noodle-variantE.png'];
    this.paddingTop = 0;
    this.differenceToRemove = 0;
    this.scrollNoodle = $('.scroll--noodle');
    this.flagSwipe = 0; // FlagSwipe make sure that we have swipe at least 1 time
    this.averageDistance = 0;
    this.pourcentageDistance = 0;
    this.totalDistance = 0;
    this.averageTime = 0;
    this.pourcentageTime = 0;
    this.totalTime = 0;
    this.maximumTime = 600; // Maximum speed time for the swipe
    this.imageManip = new ImageManip();
    this.changeClass = 'happy';
  }
  isWinner(value, time, endCounting = false) {
    // We check if the size of the noodles and the translate too.
    // We compare to make sure we scroll everything to let the player know if he won.
    this.startPoint = value;
    const newTime = 30 - time;

    if (this.startPoint > $('.scroll--noodle').innerHeight()) {
      window.location.href = `./winner.html?time=${newTime}`;
    } else if (endCounting === true) {
      window.location.href = './gameover.html';
    }
  }
  // Update the progress bar
  updateProgress(value, heightNoodles) {
    // Get the current position of the scroll
    this.startPoint = value;
    // Get the pourcentage of the progression of the player.
    this.pourcentageProgress = (this.startPoint / heightNoodles) * 100;
    // Get the width of the progress bar
    this.widthProgress = this.progressBarWidth * ((100 - this.pourcentageProgress) / 100);
    // Update the progress bar
    $('.progress--noodles').css('width', `${this.widthProgress}px`);
  }
  // Move the noise of the boar base on the speed
  moveGroin(timegroin) {
    this.timeGroin = timegroin;
    const boardGroin = $('.boar--groin');
    const animation = `groindMove ${this.timeGroin}s  0s infinite reverse ease-in-out`;
    clearInterval(this.timeoutAnim);
    boardGroin.css('animation', animation);
    // Reset animation if stop
    this.timeoutAnim = setTimeout(() => {
      boardGroin.css('animation', 'groindMove 2s 0s infinite reverse ease-in-out');
    }, 600);
  }
  updateNoodles() {
    // Get the value after swipe
    // 10 is the number of images in the dom
    this.valueAfterSwipe =
      Math.floor((this.startPoint - (this.imageHeight * 10)) / this.imageHeight);
    this.difference = this.valueAfterSwipe - this.indexPoint;
    if (this.difference !== 0) {
      this.indexPoint += this.difference;
      if (this.startPoint < this.noodlesToEat) {
        this.addImage();
      } else if (this.addLast === 0) {
        // Add the last image
        this.scrollNoodle.append('<img src="/static/noodles/noodle-end.png" alt="">');
        this.addLast = 1;
      }
    }
  }
  // Add image at the end of the scroll
  addImage() {
    for (let index = 0; index < this.difference; index += 1) {
      this.scrollNoodle.append(`<img src=" ${this.imagesArray[Math.floor(Math.random() * this.imagesArray.length)]}" alt="">`);
    }
    setTimeout(() => {
      this.removeImage(this.difference);
    }, 1000);
  }
  // To make sure we have a good performance,
  // we are removing images when they are not on the screen.
  removeImage(differenceToRemove) {
    this.differenceToRemove = differenceToRemove - 1;
    for (let index = 0; index < this.differenceToRemove; index += 1) {
      $('.scroll--noodle img:eq(0)').remove();
      this.paddingTop += this.imageHeight;
      this.scrollNoodle.css('padding-top', `${this.paddingTop}px`);
    }
  }
  // Calcultate the distance and speed to show the right face
  speedDistanceAverage(distance, time) {
    // Every 2 swipe
    if (this.flagSwipe !== 0) {
      // Get the average distance
      this.averageDistance = (this.totalDistance + distance) / 2;

      // We want the pourcentage of the screen swipe
      this.pourcentageDistance = (this.averageDistance / window.innerHeight) * 100;
      this.totalDistance = distance;

      // Need the average time between the 2 swipe
      this.averageTime = (time - this.totalTime) / 2;
      // If bigger than 1 second, we make it 1sec
      this.pourcentageTime = this.averageTime > this.maximumTime ?
        100 : ((this.averageTime / this.maximumTime) * 100);
      this.totalTime = time;

      // If distance if high and time difference is low
      const colorValuePosition =
        this.imageManip.getColor(this.pourcentageTime, this.pourcentageDistance);

      const rgbColor = colorValuePosition[0] + colorValuePosition[1] + colorValuePosition[2];

      const lookupTable = {
        531: 'happy',
        239: 'chill',
        498: 'bigeater',
        256: 'heavyeater',
        569: 'fast',
        391: 'choke',
      };

      // Change the class when you scroll
      let classToAdd = lookupTable[rgbColor];
      if (classToAdd == null) {
        classToAdd = 'chill';
      }

      clearInterval(this.changeClass);
      $('.boar--eyes').removeClass().addClass(`boar--eyes ${classToAdd}`);
      $('.boar--groin').removeClass().addClass(`boar--groin ${classToAdd}`);
      $('.boar').removeClass().addClass(`boar ${classToAdd}`);
      $('.onomatope--image').removeClass().addClass(`onomatope--image ${classToAdd}`);

      // If the use choke, it's the end.
      if (rgbColor === 391) {
        const element = document.getElementById('swipearea');
        element.parentNode.removeChild(element);
        window.setTimeout(() => {
          window.location.href = './gameover.html';
        }, 3000);
      } else {
        this.changeClass = setTimeout(() => {
          $('.boar--eyes').removeClass().addClass('boar--eyes');
          $('.boar--groin').removeClass().addClass('boar--groin');
          $('.boar').removeClass().addClass('boar chill');
          $('.onomatope--image').removeClass().addClass('onomatope--image');
        }, 1000);
      }
    } else {
      this.totalDistance = Math.abs(distance);
      this.totalTime = time;
      this.flagSwipe = 1;
    }
  }
}
