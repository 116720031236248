/* eslint-disable */
export default class ImageManip {
  constructor() {
    this.imagFile = document.getElementById('imgData');
    this.canvas = document.createElement('canvas');
    this.canvas.setAttribute("id", "canvas");
    this.canvas.width = this.imagFile.width;
    this.canvas.height = this.imagFile.height;
    this.canvas.getContext('2d').drawImage(this.imagFile, 0, 0, this.imagFile.width, this.imagFile.height);
    document.body.appendChild(this.canvas);
  }
  getColor(x, y) {
    x = x * (this.canvas.width / 100) - 1;
    y = y * (this.canvas.height / 100) - 1;
    return this.canvas.getContext('2d').getImageData(x, y, 1, 1).data;
  }
  getHexa(r, g, b) {
    let red = r.toString(16);
    red = red.length == 1 ? "0" + red : red;
    let green = g.toString(16);
    green = green.length == 1 ? "0" + green : green;
    let blue = b.toString(16);
    blue = blue.length == 1 ? "0" + blue : blue;
    return red+green+blue;
  }
}